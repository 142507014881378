import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './RelatedItems.module.css';

const RelatedItems = ({ inventoryData, currentProduct, onItemClick }) => {
    const [visibleItems, setVisibleItems] = useState([]);
    const [categoryMatches, setCategoryMatches] = useState([]); 
    const [eventTypeMatches, setEventTypeMatches] = useState([]); 

    const navigate = useNavigate();  

    useEffect(() => {
        const categoryMatches = inventoryData.filter(item => 
            item.category === currentProduct.category && item.id !== currentProduct.id
        );
        
        const eventTypeMatches = inventoryData.filter(item => 
            item.eventType === currentProduct.eventType && 
            item.id !== currentProduct.id && 
            !categoryMatches.some(i => i.id === item.id)
        );

        setCategoryMatches(categoryMatches);
        setEventTypeMatches(eventTypeMatches);

        let initialItems = [...categoryMatches.slice(0, 6)]; 
        const remainingCount = 8 - initialItems.length; 
        
        if (remainingCount > 0) {
            initialItems = [
                ...initialItems,
                ...eventTypeMatches.slice(0, remainingCount)
            ];
        }
        setVisibleItems(initialItems);
    }, [inventoryData, currentProduct]);

    const formatCost = (cost) => {
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        return new Intl.NumberFormat('en-US', options).format(cost);
    };

    const handleShowMore = () => {
        let nextItems = [];
        let remainingCategoryMatches = [...categoryMatches]; 
        let remainingEventTypeMatches = [...eventTypeMatches]; 

        remainingCategoryMatches = remainingCategoryMatches.filter(item => !visibleItems.some(i => i.id === item.id));
        remainingEventTypeMatches = remainingEventTypeMatches.filter(item => !visibleItems.some(i => i.id === item.id));

        while (nextItems.length < 4) {
            if (remainingCategoryMatches.length > 0) {
                nextItems.push(remainingCategoryMatches.shift()); 
            }
            if (remainingEventTypeMatches.length > 0 && nextItems.length < 4) {
                nextItems.push(remainingEventTypeMatches.shift());
            }
        }

        setVisibleItems(prevItems => [...prevItems, ...nextItems]);
    };

    const shouldShowMore = visibleItems.length < 16 && 
        categoryMatches.length + eventTypeMatches.length > visibleItems.length;

    const handleItemClick = (item) => {
    const productUrl = `${item.url}/${item.id}/${item.title.replace(/\s+/g, '-').toLowerCase()}`;  navigate(productUrl);
    if (onItemClick) onItemClick(item);
        };

    return (
      <div className={styles.relatedItemsContainer}>
            <h2 className={styles.relatedItemsHeader}>You might also like</h2>
            <div className={styles.relatedItemsGrid}>
                {visibleItems.length > 0 ? (
                    visibleItems.map(item => (
                        <div key={item.id} className={styles.relatedItem}>
                            <div onClick={() => handleItemClick(item)} className={styles.relatedItem}>
                                <img src={item.img1} alt={item.alt} className={styles.productImage} />
                                <div className={styles.productInfo}>
                                    <p className={styles.productTitle}>{item.title}</p>
                                    <p className={styles.productPrice}>${formatCost(item.cost)}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No related items found.</p>
                )}
            </div>
            {shouldShowMore && ( <div className={styles.showMoreContainer}>
                    <span onClick={handleShowMore} className={styles.showMoreLink}>
                        See More ↓
                    </span>
                </div>  
            )}
        </div>
    );
};

export default RelatedItems;
