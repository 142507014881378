import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import WhatsappBtn from './WhatsappBtn';
import './kevnavbar.css';
import logo from '../assets/PCI Logo brand.png';
import SignIn from './SignIn/SignIn';

function KevNavigationBar({ cartItemCount }) {
    const [isActive, setIsActive] = useState(false);
    const navRef = useRef(null);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const closeMenu = () => {
        setIsActive(false);
    };

    const handleOutsideClick = useCallback((e) => {
        if (navRef.current && !navRef.current.contains(e.target)) {
            closeMenu();
        }
    }, []); 

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]); 

    return (
        <nav className="navbar" ref={navRef}>
            <Link to="/" className="link">
                <div className="navbar-logo logo logo-regular">
                    <div className="left">
                        <img src={logo} alt="Logo" />
                        <span className="companyName">Personalized Cards & Invitations</span>
                    </div>
                </div>
                <div className="navbar-logo logo logo-shorten">
                    <div className="left">
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
            </Link>

            <ul className={`navbar-links ${isActive ? 'active' : ''}`}>
                <li className="navItem"><NavLink onClick={closeMenu} to="/wedding">Wedding</NavLink></li>
                <li className="navItem"><NavLink onClick={closeMenu} to="/corporate">Business</NavLink></li>
                <li className="navItem cart-icon">
                    <NavLink onClick={closeMenu} to="/cart">
                        My Cart <i className="fas fa-shopping-cart"></i> 
                        {cartItemCount > 0 && <span className="cart-item-count">{cartItemCount}</span>}
                    </NavLink>
                </li>
                <li className="navItem"><NavLink onClick={closeMenu} to="/reviews">Reviews</NavLink></li>
                <li className="navItem"><SignIn/></li>
                <li> <WhatsappBtn title="Whatsapp Us" /></li>
            </ul>
            <div className="navbar-toggle" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </nav>
    );
}

export default KevNavigationBar;
