import React from 'react';
import styles from './Mission.module.css';
// import aboutUs from '../../assets/images/aboutUs2.png';

const Mission = () => {
    return (
        <div className={styles.heroContainer}>
      
            <div className={styles.textContainer}>
            <h1 className={styles.headerText}>Our Mission: Your Satisfaction</h1>
            <p className={styles.subText}>At PC&I, we are dedicated to delivering not just products but experiences that leave you delighted. Our commitment to quality, creativity, and customer satisfaction is at the core of everything we do. When you choose PC&I, you're not just getting stationery; you're getting a partner who cares about your event's success as much as you do.</p>
            </div>
        </div>
    );
};

export default Mission;