import React, { useState, useCallback, useEffect } from 'react';
import styles from './CheckoutModal.module.css';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import DataProtectionPolicyModal from './DataProtection/DataProtectionPolicyModal';
import { setCookie, getCookie } from './Utils/CookieUtils';

const CheckoutModal = ({ isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [checkoutDetails, setCheckoutDetails] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
    });

    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [showPolicy, setShowPolicy] = useState(false);
    const [agreeToPolicy, setAgreeToPolicy] = useState(false);
    const [marketingEmails, setMarketingEmails] = useState(true);

    const navigate = useNavigate();

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;

    useEffect(() => {
        const policyAgreed = getCookie('policyAgreed');
        if (policyAgreed === 'true') {
            setAgreeToPolicy(true);
        }
    }, []);

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCheckoutDetails((prevDetails) => ({
            ...prevDetails,
            [name]: name === 'firstName' || name === 'lastName' ? capitalizeFirstLetter(value) : value
        }));
    };

    const formatphoneNumber = (value) => {
        const digits = value.replace(/\D/g, '');
        if (digits.length <= 3) {
            return digits;
        }
        if (digits.length <= 6) {
            return `${digits.slice(0, 3)}-${digits.slice(3)}`;
        }
        return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    };

    const handleSubmitClick = (e) => {
        e.preventDefault(); // Prevent form submission if not agreed to policy
        if (agreeToPolicy) {
            handleCheckoutSubmit();
        } else {
            setShowPolicy(true);
        }
    };

    const handlePolicyAgree = () => {
        setAgreeToPolicy(true);
        setShowPolicy(false);
        setCookie('policyAgreed', 'true', 365); // Store cookie for 1 year
        handleCheckoutSubmit();
    };

    const handlePolicyDisagree = () => {
        alert("You must agree to the policy to place an order.");
    };

    const handleCheckoutSubmit = async () => {
        setEmailError('');
        setPhoneError('');

        if (!emailPattern.test(checkoutDetails.email)) {
            setEmailError('Invalid email address');
            return;
        }

        if (!phoneNumberPattern.test(checkoutDetails.phoneNumber)) {
            setPhoneError('Invalid phone number (000-000-0000)');
            return;
        }

        try {
            setLoading(true);
            const cart = JSON.parse(localStorage.getItem('cart')) || [];

            const cartTable = `
                <table style="width: 100%; border: 1px solid #ddd; border-collapse: collapse;">
                    <thead>
                        <tr>
                            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">ProductID</th>
                            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Name</th>
                            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Quantity</th>
                            <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${cart.map(item => ` 
                            <tr>
                                <td style="padding: 8px; border: 1px solid #ddd;">${item.id}</td>
                                <td style="padding: 8px; border: 1px solid #ddd;">${item.title}</td>
                                <td style="padding: 8px; border: 1px solid #ddd;">${item.quantity}</td>
                                <td style="padding: 8px; border: 1px solid #ddd;">$${item.price}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            `;

            const agreement = marketingEmails ? "I agreed to business policy and receiving marketing emails" : "I agreed to business policy only";

            const orderDetails = {
                ...checkoutDetails,
                cart: cartTable,
                agreement
            };

            await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                orderDetails,
                process.env.REACT_APP_EMAILJS_API_KEY
            );

            navigate('/order-confirmation');

        } catch (err) {
        } finally {
            setLoading(false);
            onClose();
        }
    };

    const memoizedOnClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        isOpen && (
            <div className={styles.modal}>
                <div className={styles['modal-content']}>
                    <h2 className={styles['header']}>Enter your details</h2>
                    <form onSubmit={handleSubmitClick}>
                        <div className={styles.inputGroup}>
                            <div className={styles.inlineInput}>
                                <div className={styles.inlineInputItem}>
                                    <label htmlFor='firstName'>First Name:</label>
                                    <input
                                        id='firstName'
                                        type="text"
                                        name="firstName"
                                        required
                                        className={styles.inputField}
                                        value={checkoutDetails.firstName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={styles.inlineInputItem}>
                                    <label htmlFor='lastName'>Last Name:</label>
                                    <input
                                        id='lastName'
                                        type="text"
                                        name="lastName"
                                        required
                                        className={styles.inputField}
                                        value={checkoutDetails.lastName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className={styles.inlineInput}>
                                <div className={styles.inlineInputItem}>
                                    <label htmlFor='phoneNumber'>Phone Number:</label>
                                    <input
                                        id='phoneNumber'
                                        type="tel"
                                        name="phoneNumber"
                                        required
                                        className={`${styles.inputField} ${phoneError ? styles.invalid : ''}`}
                                        value={checkoutDetails.phoneNumber}
                                        onChange={(e) => handleChange({ target: { name: 'phoneNumber', value: formatphoneNumber(e.target.value) } })}
                                    />
                                    {phoneError && <p className={styles.error}>{phoneError}</p>}
                                </div>
                                <div className={styles.inlineInputItem}>
                                    <label htmlFor='email'>Email Address:</label>
                                    <input
                                        id='email'
                                        type="email"
                                        name="email"
                                        required
                                        className={`${styles.inputField} ${emailError ? styles.invalid : ''}`}
                                        value={checkoutDetails.email}
                                        onChange={handleChange}
                                    />
                                    {emailError && <p className={styles.error}>{emailError}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={styles.centerBtn}>
                            <button type="submit" className={styles.submitButton} disabled={loading}>
                                Submit
                            </button>
                            <button type="button" onClick={memoizedOnClose} className={styles.closeButton}>
                                Close
                            </button>
                        </div>
                    </form>
                </div>
                <DataProtectionPolicyModal
                    showPolicy={showPolicy}
                    marketingEmails={marketingEmails}
                    setMarketingEmails={setMarketingEmails}
                    onAgree={handlePolicyAgree}
                    onDisagree={handlePolicyDisagree}
                />
            </div>
        )
    );
};

export default CheckoutModal;
