import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './TermsAndConditions.module.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy - Personalized Cards and Invitations</title>
                <meta name="description" content="Review the Privacy Policy at Personalized Cards and Invitations. This page outlines the rules, policies, and legal agreements governing the use of our website and services for custom cards and invitations." />
            </Helmet>
            <HeaderSection title="Privacy Policy" />
            <div className={styles.termsContainer}>
                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Data Protection Policy</h2>
                    <p>
                        Personalized Cards and Invitation is committed to protecting your personal information. This policy outlines how we collect, use, and protect your data when you place an order through our online store based in Jamaica.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Information We Collect</h2>
                    <p>
                        When you place an order, we collect the following information:
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Selected items and quantities</li>
                        </ul>
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>How We Use Your Information</h2>
                    <p>
                        The information we collect is used solely for business purposes, including:
                        <ul>
                            <li>Fulfilling your orders</li>
                            <li>Sending emails regarding your purchase</li>
                            <li>Sending marketing and promotional emails (with your consent)</li>
                            <li>Storing your reviews (Firebase) for customer feedback on products</li>
      
                        </ul>
                        We use <strong>Zoho Books</strong> to manage our accounting and financial records. The data shared with Zoho Books includes your name, contact details, and order information for invoicing and bookkeeping purposes only.
  
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Data Protection and Security</h2>
                    <p>
                    We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Our website uses Firebase Authentication, which ensures that your login and personal information are securely stored and transmitted, using industry-standard encryption. Firebase Authentication protects user data with robust security rules and session management, providing an added layer of protection against unauthorized access.  </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Data Sharing</h2>
                    <p>
        We do not sell or share your personal information with third parties, except as necessary to fulfill your order or as required by law. Your data may be shared with third-party services such as Zoho Books and EmailJS for the purpose of managing orders and sending you necessary communications related to your purchase.
    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Cookies and Tracking Technologies</h2>
                    <p>
                        We use cookies on our website to ensure essential functionality and improve your experience. Cookies are small text files placed on your device that help us track user preferences, improve navigation, and ensure the website functions properly. The cookies we use are essential for the operation of our website and cannot be disabled.
                    </p>
                    <p>
                        By using our website, you consent to our use of cookies. You can manage your cookie preferences through your browser settings, but please note that disabling cookies may affect the functionality of the site.
                    </p>
                </section>
                <section className={styles.section}>
    <h2 className={styles.termsHeader}>International Data Transfers</h2>
    <p>
        Our website is hosted on Netlify, and our database is stored on Firebase, with infrastructure located in the United States. As a result, your personal data may be transferred to, and stored in, the United States, which may have different data protection laws than those in Jamaica.
    </p>
    <p>
        By using our services, you consent to the transfer, storage, and processing of your data in the United States. We take reasonable measures to ensure that your data is handled securely and in compliance with applicable data protection laws.
    </p>
</section>
                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Your Rights</h2>
                    <p>
                        You have the right to:
                        <ul>
                            <li>Request access to the personal information we hold about you</li>
                            <li>Request correction of any inaccurate or incomplete data</li>
                            <li>Request the deletion of your personal information (subject to legal and contractual obligations)</li>
                            <li>Withdraw consent for marketing communications at any time</li>
                        </ul>
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Contact Us</h2>
                    <p>
                        If you have any questions or concerns about our Data Protection Policy, please contact us at pci.customerquery@gmail.com.
                    </p>
                </section>
                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Changes to the Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will include the date of the most recent revision. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                    </p>
                </section>
                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>Notice</h2>
                    <p>
                        By placing an order with Personalized Cards and Invitation, you agree to our Data Protection Policy.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
