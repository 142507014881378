import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../Database/firebase'; // Import auth from Firebase
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'; // Modular imports for auth
import styles from './SignIn.module.css';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const SignIn = () => {
  // Get user state from Firebase auth
  const [user, loading, error] = useAuthState(auth);

  // useEffect(() => {
  //   // If user is logged in, you could do some redirect or actions here
  //   if (user) {
  //     console.log('User is signed in:', user);
  //   }
  // }, [user]);

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider(); 
      await signInWithPopup(auth, provider); 
    } catch (err) {
      console.error("Error signing in:", err);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error("Error signing out:", err);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      {!user ? (
        <button className={styles['signin-btn']}  onClick={handleSignIn}>Continue with Google <span><FontAwesomeIcon icon={faGoogle} />
</span></button>
      ) : (
        <div>
          <button className={styles['signin-btn']} onClick={handleSignOut}>Logout <span><FontAwesomeIcon icon={faRightFromBracket} />
          </span></button>
        </div>
      )}
    </div>
  );
};

export default SignIn;
