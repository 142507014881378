import React from 'react';
import Hero from '../../components/Hero/HeroHeader';
import OpeningHours from '../../components/OpeningHours';
import FeaturedCategories from '../../components/FeaturedCategories';
import FeaturedProducts from '../../components/FeaturedProducts';
import ReviewsCards from '../../components/ReviewsCards';
// import heroBg from '../../assets/images/herobg2.jpg';
import styles from '../../components/Background.module.css';
import { Helmet } from 'react-helmet';

const Landing = () => {
    return (
        <div className={styles['background-container']}>
           <Helmet>
            <title>Personalized Cards and Invitations - Custom Greeting Cards for Every Occasion</title>
            <meta name="description" content="This is a description of my page" />
            <meta name="keywords" content="react, helmet, seo" />
            </Helmet>

           
          <Hero/>
<FeaturedCategories/>
<FeaturedProducts/>
            <ReviewsCards/>
            <OpeningHours/>
        </div>
    );
};

export default Landing;