import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BackBtn from './BackBtn';
import Button from './AddToCartBtn';
import inventoryData from '../data/inventoryData';
import styles from './Product.module.css';
import { Helmet } from 'react-helmet';
import RelatedItems from '../components/RelatedItems/RelatedItems';
import { database, auth, ref, push , get} from '../Database/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import SignIn from '../components/SignIn/SignIn'; 

const Product = ({ addToCart }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  const [isCustomerRequestModalOpen, setIsCustomerRequestModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { productId } = useParams();
  const [currentData, setCurrentData] = useState(inventoryData.find(item => item.id === productId));

  const [imageSelector, setImageSelector] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);

  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');
  const [user] = useAuthState(auth); // Access signed-in user

  const [reviews, setReviews] = useState([]); // State to hold reviews
  const [visibleReviews, setVisibleReviews] = useState([]); // Reviews to display initially
  const [showAllReviews, setShowAllReviews] = useState(false); // Flag to track whether all reviews are visible


  const imageSelectorHandler = (e, param) => {
    setImageSelector(param);
    window.scrollTo(0, 0);
  };

 useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewRef = ref(database, `reviews/${productId}`);
        const snapshot = await get(reviewRef);
        if (snapshot.exists()) {
          const reviewsData = snapshot.val();
          const reviewsArray = Object.keys(reviewsData).map(key => ({
            id: key,
            ...reviewsData[key],
          }));
          setReviews(reviewsArray);
          setVisibleReviews(reviewsArray.slice(0, 3)); // Show only the 3 newest reviews initially
        } else {
          setReviews([]);  
          setVisibleReviews([]);
        }
      } catch (err) {
        console.error('Error fetching reviews: ', err);
      }
    };
    fetchReviews();
}, [productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!user) {
      alert('You must be signed in to leave a review');
      return;
    }
  
    if (!review || rating === 0) {
      setError('Please provide a rating and comment');
      return;
    }
  
    try {
       await push(ref(database, `reviews/${productId}`), {
        userId: user.uid,
        firstName: user.displayName.split(' ')[0], // First Name
        lastName: user.displayName.split(' ')[1] || '', // Last Name
        review,
        rating,
        timestamp: new Date().toISOString(),
      });
  
      setReview('');
      setRating(0);

         const reviewRef = ref(database, `reviews/${productId}`);
         const snapshot = await get(reviewRef);
         if (snapshot.exists()) {
           const reviewsData = snapshot.val();
           const reviewsArray = Object.keys(reviewsData).map(key => ({
             id: key,
             ...reviewsData[key],
           }));
           setReviews(reviewsArray);
         }
       } catch (err) {
         setError('Error submitting review. Please try again later.');
         console.error(err);
       }
  };
  


  const formatCost = (cost) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    return new Intl.NumberFormat('en-US', options).format(cost);
  };

  const addToCartHandler = () => {
    const newItem = {
      id: currentData.id,
      img: currentData.img1,
      title: currentData.title,
      quantity: quantity,
      price: currentData.cost,
      desc: currentData.desc,
      event: currentData.eventType
    };

    addToCart(newItem);

    setIsCustomerRequestModalOpen(true);
  };

  const handleGoToCart = useCallback(() => {
    setIsCustomerRequestModalOpen(false);
    navigate('/cart');
  }, [navigate]);

  const handleContinueShopping = () => {
    setIsCustomerRequestModalOpen(false);
  };

  const handleItemClick = (item) => {
    setCurrentData(item);
    setImageSelector('');
    setQuantity(1);
    window.scrollTo(0, 0);
  };

  const handleCopyLink = () => {
    const productLink = `https://pci-ja.com/${currentData.eventType}/${currentData.id}/${currentData.title.replace(/\s+/g, '-').toLowerCase()}`;
    navigator.clipboard.writeText(productLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000); // Reset "copied" message after 2 seconds
    });
  };

  if (!currentData) {
    return <p>Product not found</p>;
  }
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero for single-digit days
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for single-digit months
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
    const renderStars = (rating) => {
    const fullStars = '★';
    const emptyStars = '☆';
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <span key={index} style={{ color: index < rating ? 'gold' : 'gray' }}>
            {index < rating ? fullStars : emptyStars}
          </span>
        ))}
      </>
    );
  };
  const handleShowMore = () => {
    setShowAllReviews(true);
    setVisibleReviews(reviews);   };
  return (
    <Fragment>
      <Helmet>
        <title>{currentData.title} - Personalized Cards and Invitations</title>
        <meta name="description" content={currentData.desc} />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={currentData.title} />
        <meta property="og:description" content={currentData.desc} />
        <meta property="og:image" content={`https://pci-ja.com/public/${currentData.img1}`} />
        <meta property="og:url" content={`https://pci-ja.com/${currentData.eventType}/${currentData.id}/${currentData.title.replace(/\s+/g, '-').toLowerCase()}`} />
        <meta property="og:type" content="product" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentData.title} />
        <meta name="twitter:description" content={currentData.desc} />
        <meta name="twitter:image" content={currentData.img1} />
        <meta name="twitter:url" content={`https://pci-ja.com/${currentData.eventType}/${currentData.id}/${currentData.title.replace(/\s+/g, '-').toLowerCase()}`} />
      </Helmet>
      
      <main className={styles.container}>
        <div className={styles['left-column']}>
          {isDesktop && <BackBtn />}
          <img src={imageSelector || currentData.img1} alt="product" loading="lazy" />
        </div>
        <div className={styles['right-column']}>
          <div className={styles['product-description']}>
            <span>{currentData.category} / {currentData.title} / {currentData.id}</span>
            <h1>{currentData.title}</h1>
            <p>{currentData.desc} (MOQ {currentData.moq})</p>
          </div>

          <div className={styles['product-configuration']}>
            <div className={styles['product-color']}>
              <p>Images</p>
              <div className={styles['product-img-choose']}>
                <img className={styles['product-mini-img']} src={currentData.img1} alt="img1" onClick={(e) => imageSelectorHandler(e, currentData.img1)} loading="lazy" />
                <img className={styles['product-mini-img']} src={currentData.img2} alt="img2" onClick={(e) => imageSelectorHandler(e, currentData.img2)} loading="lazy" />
                {currentData.img3 && <img className={styles['product-mini-img']} src={currentData.img3} alt="img3" onClick={(e) => imageSelectorHandler(e, currentData.img3)} loading="lazy" />}
              </div>
            </div>
            <div className={styles['product-description']}>
              <p>{currentData.include}</p>
            </div>
          </div>

          <div className={styles['product-quantity']}>
            <label htmlFor="quantity">Quantity: </label>
            <select id="quantity" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}>
              {[...Array(100).keys()].map(i => (
                <option key={i} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>

          <div className={styles['product-price']}>
            <p>{formatCost(currentData.cost)}</p>
            <Button title="Add to Cart" onClick={addToCartHandler} />
          </div>
       
          <div className={styles['social-share']}>
            <p>Share this product:</p>
            <div className={styles['social-icons']}>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=https://pci-ja.com/${currentData.eventType}/${currentData.id}/${currentData.title.replace(/\s+/g, '-').toLowerCase()}`} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f" style={{ fontSize: '32px', marginRight: '15px' }}></i>
              </a>
              <a href={`https://twitter.com/intent/tweet?url=https://pci-ja.com/${currentData.eventType}/${currentData.id}/${currentData.title.replace(/\s+/g, '-').toLowerCase()}&text=${currentData.title}`} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter" style={{ fontSize: '32px', marginRight: '15px' }}></i>
              </a>
              <a href={`https://api.whatsapp.com/send?text=Check%20out%20this%20product%20https://pci-ja.com/${currentData.eventType}/${currentData.id}/${currentData.title.replace(/\s+/g, '-').toLowerCase()}`} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp" style={{ fontSize: '32px', marginRight: '15px' }}></i>
              </a>
              <button onClick={handleCopyLink} className={styles['copy-link-button']}>
                {linkCopied ? 'Link Copied!' : 'Copy Link'}
              </button>
            </div>
          </div>

         <div>
  <h3>Reviews</h3>
  {visibleReviews.length > 0 ? (
            visibleReviews.map((review) => (
              <div key={review.id} className={styles.reviewItem}>
                <div className={styles.reviewHeader}>
                  <span className={styles.reviewName}>
                    <strong>{review.firstName}</strong> {renderStars(review.rating)}
                  </span>
                  <span className={styles.reviewDate}>
                    {formatTimestamp(review.timestamp)}
                  </span>
                </div>
                <p>{review.review}</p>
              </div>
    ))
  ) : (
    <p>No reviews yet. Be the first to leave a review!</p>
  )}
  {!showAllReviews && reviews.length > 3 && (
            <button onClick={handleShowMore} className={styles.showMoreBtn}>
              View More &gt;
            </button>
          )}
</div>
          <div className={styles['product-review']}>
    <h4>Leave a Review</h4>
    {!user ? (
      <SignIn />   ) : (
      <div className={styles['review-form']}>
        <textarea
          placeholder="Write your review here"
          value={review}
          onChange={(e) => setReview(e.target.value)}
        />
       <div className={styles['rating-stars']}>
        <label>Rating:</label>
        <div className={styles['stars']}>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={styles['star']}
              onClick={() => setRating(star)}
              style={{ color: star <= rating ? 'gold' : 'gray', cursor: 'pointer' }}
            >
              ★
            </span>
          ))}
        </div>
      </div>
        {error && <p className={styles['error']}>{error}</p>}
        <Button title="Submit Review" onClick={handleSubmit} />
      </div>
    )}
  </div>
         
        </div>
      </main>

      {isCustomerRequestModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <p>Item added to cart! Would you like to go to the cart or continue shopping?</p>
            <div className={styles.modalButtons}>
              <button onClick={handleGoToCart}>Go to Cart</button>
              <button onClick={handleContinueShopping}>Continue Shopping</button>
            </div>
          </div>
        </div>
      )}

      <RelatedItems
        inventoryData={inventoryData}
        currentProduct={currentData}
        onItemClick={handleItemClick}
      />
    </Fragment>
  );
};

export default Product;
